import React, { useState } from "react";
import "./App.css";
import Swal from "sweetalert2";

function App() {
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateContent = async () => {
    if (!prompt.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Lah Kocak",
        text: "Tulis dulu pertanyaannya, jangan kosong",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    setIsLoading(true);
    setResponse("");
    try {
      const res = await fetch("https://genz.adagempa.cloud/generate-content", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt }),
      });

      if (!res.ok) {
        throw new Error("Failed to generate content");
      }

      const data = await res.json();
      setResponse(data.generatedText);
    } catch (error) {
      console.error("Error:", error);
      setResponse("Error generating content.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleGenerateContent();
    }
  };

  const formatResponse = (text) => {
    const parts = text.split(/(\*[^*]+\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith("*") && part.endsWith("*")) {
        return <strong key={index}>{part.slice(1, -1)}</strong>;
      } else {
        return part;
      }
    });
  };

  return (
    <div className="relative w-full min-h-screen flex items-center justify-center p-6 bg-gray-50">
      <div className="w-full max-w-3xl bg-white rounded-lg shadow-md p-12 flex flex-col items-center space-y-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-800">Hi, Gen Z 👋</h1>
          <p className="text-gray-500 mt-2">
            Kolaborasi React JS dengan menggunakan Gemini Google
          </p>
          <p className="text-gray-500 mt-2">
            Kalo misal nanti ada error, berarti responsenya udah mentok, coba
            refresh lagi halamannya. Maklum mode hemat mahasiswa hehe
          </p>
        </div>

        <div className="w-full flex items-center bg-white rounded-full shadow-md p-4">
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Tanyakan sesuatu..."
            className="flex-grow px-6 py-4 text-gray-700 focus:outline-none"
          />
          <button
            onClick={handleGenerateContent}
            className="bg-orange-500 hover:bg-orange-600 text-white rounded-full px-6 py-4 ml-4 text-lg"
            disabled={isLoading}
          >
            {isLoading ? "Mengirim..." : "Kirim"}{" "}
            {!isLoading && <i className="fas fa-arrow-right ml-2"></i>}
          </button>
        </div>

        <div className="text-center w-full bg-gray-100 rounded-lg p-4 mt-4">
          {isLoading ? (
            <p className="text-gray-500">Menunggu respons...</p>
          ) : (
            formatResponse(response)
          )}
        </div>
        <p className="text-gray-500 mt-2 text-center">
          © 2024 Chaidar Aria | Gen Z Chat. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default App;
